<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <ExportContainer />
            </div>
        </div>
    </div>
</template>

<script>
import ExportContainer from './components/OutputPage/ExportContainer.vue';

export default {
    name: 'MarketData-OutputPage',
    components: {
        ExportContainer,
    },
};

</script>

<style scoped></style>