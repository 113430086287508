<template>
    <div class="card" style="margin-bottom: 20px;" v-if="selectedTopic">
        <header class="card" style="padding: 20px 0 10px 10px;">
            <div style="display: flex; align-items: center; ">
                <h5 class="exportContainer-header" style="margin-bottom: 0;">
                    Export For: <strong> {{ selectedTopic.name }} </strong>
                </h5>
                <Button 
                    class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                    icon="pi pi-question-circle" 
                    @click="goToHeaderPage('exportPage-header')" 
                />
            </div>
        </header>
        <main class="card "
            style="
                padding: 20px 20px 10px 10px;
            "
        >
            <div style="
                    width: 20%; 
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 1rem;
                " >
                    <Button
                        label="Programmes per Country Overview"
                        class="p-button-info exportContainer-button"
                        @click="goProgramsPerCountry()"
                    />
                    <Button 
                        label="Contracts per Company Overview"
                        class="p-button-info exportContainer-button"
                        @click="goContractPerCompany()"

                    />
                    <Button
                        label="Company profiles"
                        class="p-button-info exportContainer-button"
                        disabled
                    />
                    <Button 
                        label="Company list"
                        class="p-button-info exportContainer-button"
                        disabled
                    />
                    <Button 
                        label="Forecast data"
                        class="p-button-info exportContainer-button"
                        @click="goOutput()"
                    />
                    <Button 
                        label="Country Data"
                        class="p-button-info exportContainer-button"
                        @click="goCountryData()"
                    />
            </div>
        </main>
    </div>
    <div class="card" v-else >
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import redirectHelperPage from '../../../../../../../shared/helpers/redirectToHelperPage';
import Button from 'primevue/button';

export default {
    name: 'ExportContainer',
    components:{
        Button,
        ProgressSpinner,
    },

    setup: () => ({}),
    data(){
        return {

        }
    },
    async created(){

    },
    mounted(){
        
    },
    computed: {
        selectedTopic(){
            return this.$store.getters.obtainSelectedTopic;
        }

    },

    methods:{
        goToHeaderPage(section){
          redirectHelperPage(section)
        },

        goProgramsPerCountry() {
            this.$router.push(`./output/programpercountry/${this.selectedTopic.topicId}`)
        },

        goContractPerCompany() {
            this.$router.push(`./output/contractpercompany/${this.selectedTopic.topicId}`)
        },

        goOutput() {
            this.$router.push(`./output/forecastData/${this.selectedTopic.topicId}`)
        },

        goCountryData(){
            this.$router.push(`./output/countryDataOutput/${this.selectedTopic.topicId}`)
        }
    },
    watch:{
    },
}

</script>

<style scoped>
.exportContainer-header{
    font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}
.exportContainer-button{
    text-align: start;
}
</style>

